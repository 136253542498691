.navbar-expand-lg .navbar-nav .dropdown-menu { background: #fff; overflow: hidden; border: 1px solid #ccc; border-top: 0;  border-radius: 0 0 10px 10px; padding: 0; line-height: 35px;}

.mobile-dis-n{display: block}
.mobile-dis-b{display: none}

.section-dis{display: none;}
header{box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.16);  position: fixed; top: 0; width: 100%; background: #fff; z-index: 20;}
textarea{resize: none;}
.space-header{margin-top: 100px;}
.space-header-2{margin-top: 117px;}
.top-bar-main{font-size: 14px;}
.top-bar{display: flex; background: #222222; overflow: hidden;}
.top-bar a{ color: #fff; line-height: 48px;}.footer-copyright
.top-bar a:hover{ color: #ccc; line-height: 48px;}
.top-bar-l{float: left; }
.top-bar-l a{ color: #fff;font-size: 14px;}
.top-bar-l ul{padding-left: 0;}
.top-bar-l ul li{float: left; margin-right: 20px;}
.top-bar-r-btn a {
  font-weight: 500;
}
.top-bar-r {
    float: right;
}
.ts-feature-box .readmore {
  text-transform: uppercase;
}
.top-bar-r{float: right;}
.top-bar-r a{margin-left: 20px;}
.top-bar-r a img{max-width: 185px;}
.top-bar-r-btn a, .banner-l a, .similar-issue-2 p a, .service-complaint-r form input[type="submit"],  .service-complaint-r form button[type="submit"],.service-banner-l a, .fc-form form input[type="submit"], .fc-form form button[type="submit"],.about-work-l a, .about-partner-l p a, .about-invest a, .login-form form input[type="submit"],  .login-form form button[type="submit"], .login-form form input[type="button"],.career-open-1 a, .career-feed-2-r a, .career-details-3 a, .chat-boat-4 a, .footer-popup-r p a, .page-404-in a, .thank-you-r a{ transition: .3s; background: #222222; border: 2px solid #222222; line-height: 40px; display: inline-block; padding: 0 20px; border-radius: 40px; color: #fff; margin-left: 15px;}
.top-bar-r-btn a:hover, .top-bar-r-btn a.select-1:hover,  .banner-l a:hover, .service-banner-l a:hover, .similar-issue-2 p a:hover, .service-complaint-r form input[type="submit"]:hover, .service-complaint-r form button[type="submit"]:hover,.fc-form form input[type="submit"]:hover,  .fc-form form button[type="submit"]:hover, .about-work-l a:hover, .about-partner-l p a:hover, .about-invest a:hover, .login-form form input[type="submit"]:hover, .login-form form button[type="submit"]:hover,  .login-form form input[type="button"]:hover, .career-open-1 a:hover, .career-open-1 a.select-1:hover, .career-feed-2-r a:hover, .career-details-3 a:hover, .chat-boat-4 a:hover, .footer-popup-r p a:hover, .page-404-in a:hover, .thank-you-r a:hover{background: #faaa52; color: #222; transition: .3s;}
.top-bar-r-btn a.select-1{background: #fff;color: #222; }
.navigation-1{ font-weight: 600;}
.navigation-1 .navbar-nav-2{margin-left: auto;}
.navigation-1 .navbar-nav-2 .nav-item .nav-link{padding: 0 10px; color: #222222; font-size: 14px;font-weight: 500;}
.navigation-1 .navbar-nav-2 .nav-item .nav-link:hover{color: #faaa52;}
.navigation-1 .navbar-nav-2 .nav-item a.active {color: #faaa52;}
.dropdown-item{font-size: 14px; }
