.container-abdm {
    margin-top: 8rem;
}

/* .main-box {
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
} */

.left-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 9px;
}

.left-content .subtitle {
    color: #476BC7;
    font-family: 'Rubik';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}

.input-field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 17px;
}

.input-fields-text {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
}

.input-fields-text .input-text {
    display: flex;
    padding: 2px 22px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    border: 2px solid #193656;
    background: #FFF;
    width: 90px;
}

.left-content .red-start {
    color: #FB7369;
    font-family: 'Rubik';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}

.left-content p {
    display: flex;
    align-items: flex-start;
    color: #193656;
    font-family: 'Rubik';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.left-content textarea {
    overflow: hidden;
    color: #000;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 137.5% */
}

.agree-text {
    display: flex;
    align-items: center;
    gap: 10px;
}

.buttons-div {
    display: flex;
    align-items: flex-start;
    gap: 15px;
}

.buttons-div .btn-dark {
    display: flex;
    padding: 2px 22px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    border: 2px solid #263A5B;
    background: #263A5B;
}
.para{
    height: 100px;
    width: 720px;
}
.buttons-div .btn-light {
    display: flex;
    padding: 2px 22px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    border: 2px solid var(--text-main, #263A5B);
    background: #FFF;
}

.right-btn-sec {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

.btn-active {
    display: flex;
    height: 44px;
    padding: 2px 22px;
    justify-content: center;
    align-items: center;
    color: #FFF;
    gap: 10px;
    border-radius: 5px;
    background: #476BC7;
}

.btn-success-dark {
    display: flex;
    height: 44px;
    padding: 2px 22px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #FFF;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    /* 250% */
    border-radius: 5px;
    border: 2px solid #193656;
    background: #193656;
}

.btn-inactive {
    display: flex;
    height: 44px;
    padding: 2px 22px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    border: 2px solid #193656;
    background: #FFF;
}

.right-btn-sec .step-number {
    color: #FFF;
    text-align: center;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}

.right-btn-sec .btn-inactive .step-number {
    border-radius: 25px;
    border: 2px solid rgba(38, 58, 91, 0.1);
    color: #263A5B;
    /* border-radius: 25%; */
    background: #FFF;
}

.generate-otp-msg {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
}

.input-box {
    display: flex;
    width: 457px;
    min-height: 42px;
    padding: 14px 30px 13px 30px;
    align-items: flex-start;
    border-radius: 4px;
    border: 1px solid #CED4DA;
    background: #FFF;
}
@media (max-width: 768px) {
    .container-abdm {
        margin-top: 14rem; /* Adjust margin-top for smaller screens */
    }

    .left-content {
        gap: 6px; /* Adjust the gap between items for smaller screens */
    }
    .left-content .subtitle {
        font-size: 18px; /* Decrease font size for smaller screens */
    }

    .input-field {
        gap: 12px; /* Adjust the gap between input fields for smaller screens */
    }

    .input-fields-text {
        gap: 5px; /* Adjust the gap between items in input-fields-text for smaller screens */
    }
    .input-fields-text .input-number {
        width: 100%; /* Make input fields full width on smaller screens */
    }

    .left-content .red-start {
        font-size: 18px; /* Decrease font size for smaller screens */
    }

    .left-content p {
        font-size: 14px; /* Decrease font size for smaller screens */
    }

    .left-content textarea {
        font-size: 14px; /* Decrease font size for smaller screens */
    }
    .buttons-div{
        flex-direction: row; 
        margin-top: 10px;
    }
    .right-btn-sec {
        flex-direction: column; /* Change direction to column on smaller screens */
        align-items: center;
        margin-top: 30px;
    }

    .buttons-div .btn-dark,
    .buttons-div .btn-light,
    .btn-success-dark,
    .btn-inactive {
        width: 100%; /* Make buttons full width on smaller screens */
    }

    .agree-text {
        flex-wrap: wrap; /* Allow text to wrap on smaller screens */
    }
    .para {
        height: 100px;
        width: 100%; /* Adjust width to fit the container */
        max-width: 720px; /* Set maximum width */
        margin: 0 auto; /* Center the element horizontally */
    }
}