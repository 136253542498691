h1 {
    color: #263A5B;
    font-family: 'Open Sans';
    font-size: 42px;
    font-style: normal;
    font-weight: 800;
    line-height: 66px;
    text-transform: uppercase;
}

p.banner-btn a.tel-number:hover,
.more-btn:hover,
.more-btn a:hover {
    background: #FB7369;
    color: #FFF;
    transition: .3s;
    border: 1px solid #FB7369;
}

.top-bar-r-btn a:hover,
.top-bar-r-btn a.select-1:hover,
.banner-l a:hover,
.service-banner-l a:hover,
.similar-issue-2 p a:hover,
.service-complaint-r form input[type="submit"]:hover,
.service-complaint-r form button[type="submit"]:hover,
.fc-form form input[type="submit"]:hover,
.fc-form form button[type="submit"]:hover,
.about-work-l a:hover,
.about-partner-l p a:hover,
.about-invest a:hover,
.login-form form input[type="submit"]:hover,
.login-form form button[type="submit"]:hover,
.login-form form input[type="button"]:hover,
.career-open-1 a:hover,
.career-open-1 a.select-1:hover,
.career-feed-2-r a:hover,
.career-details-3 a:hover,
.chat-boat-4 a:hover,
.footer-popup-r p a:hover,
.page-404-in a:hover,
.thank-you-r a:hover {
    background: #FB7369;
    color: #FFF;
    transition: .3s;
}

h2,
.post .entry-header .entry-title,
article.page .entry-header .entry-title {
    font-family: "Open Sans";
    font-weight: 800;
}

h3 {
    color: #263A5B !important;
    font-family: 'Open Sans';
    font-size: 50px;
    font-weight: 800;
    line-height: 44px;
    text-transform: uppercase;
}

h4 {
    font-family: "Open Sans";
    font-weight: 800;
}

body {
    font-family: "Rubik" !important;
}

.space-header-2 {
    margin-top: 50px;
}

.tg-1-in {
    background: none;
}

.tg-1 {
    background: none;
}

.carousel-indicators li,
.slick-dots li {
    opacity: 1;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: rgba(34, 47, 90, 0.20);
    color: #222;
    border-top: 0;
    border-bottom: 0;
}

.carousel-indicators li.active,
.slick-dots li.slick-active {
    width: 15px;
    height: 15px;
    border-radius: 7.5px;
    background-color: #fff;
    border: 1px solid var(--primary-2, #476BC7);
}

.carousel-indicators,
.slick-dots {
    display: flex;
    padding: 8px 0px;
    justify-content: center;
    align-items: center;
    gap: 11px;
}

.banner-l a.tel-number {
    border-radius: 5px;
    border: 2px solid #222;
    background: #FFF;
    color: #0F1F30;
    text-align: center;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
}

.tg-2 {
    background: #fff;
    padding: 15px;
    border-radius: 12px;
    display: flex;
    width: 342px;
    padding: 39px 40px 40px 40px;
    background: #FFF;

}

.tg-1.why-eseclaim-bg {
    background-color: #fff !important;
    /* box-shadow: 0 0 0 10px  rgba(0, 0, 0, 0.07); */
}

.cal-content p {
    color: #193656;
    font-family: Rubik;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    width: 474px;
}

.learn-more.btn.btn-primary {
    border-radius: 5px;
    background: var(--primary-1, #FB7369);
    color: #FFF;
    text-align: center;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 35px;
    text-transform: uppercase;
}

/* .counter-main {
    background: url('../assets/img/calendar-img.png');
    background-position: center;
    margin: 3rem 0;
    background-repeat: no-repeat;
    background-size: cover;
    height: 425px;
} */

.head-heading {
    display: flex;
    /* width: 428px; */
    justify-content: flex-end;
    align-items: center;
    gap: 31px;
    flex-wrap: nowrap;
}

h2.heading-2 {

    color: var(--text-main, #263A5B);
    text-align: right;
    font-family: 'Open Sans';
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: 38px;
    text-transform: uppercase;
    width: 165px;
}

.row.head {
    display: inline-flex;
    padding-right: 0px;
    align-items: stretch;
    padding-bottom: 0;
}

.element-border-divider.element-style-long {
    width: 5px;
    height: 70px;
    background: var(--primary-2, #476BC7);
}

.head-para p {
    width: 340px;
    flex-shrink: 0;
    color: #193656;
    font-family: 'Rubik';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;

}


.ts-feature-box {
    margin-bottom: 30px;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #edeff2;
    border-radius: 5px 5px 5px 5px;
}

.ts-feature-box.style2 {
    padding: 20px 20px 20px 40px;
    -webkit-box-shadow: 0px 35px 50px 0px rgba(0, 0, 0, 0.07);
    box-shadow: 0px 35px 50px 0px rgba(0, 0, 0, 0.07);
}

.ts-feature-box {
    -webkit-box-shadow: 0px 35px 50px 0px rgba(0, 0, 0, 0.07);
    box-shadow: 0px 35px 50px 0px rgba(0, 0, 0, 0.07);
    padding: 20px;
    background: #fff;
}

.ts-feature-box.style2:hover {
    box-shadow: none;
}

.ts-feature-box .ts-title,
.elementor-7 .elementor-element.elementor-element-ee9cca3 .media-body,
.elementor-7 .elementor-element.elementor-element-ee9cca3 .ts-feature-box .feature-icon {
    text-align: left;
}

.ts-feature-box .ts-title {
    margin-bottom: 30px;
}

.ts-title.md {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.72px;
    text-transform: uppercase;
    font-weight: 800;
}

.media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.ts-feature-box .feature-icon {
    text-align: left;
}

.service-thumb-bg .post-thumb {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
    display: block;
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
}

.ts-feature-box .feature-icon {
    max-width: 70px;
    margin-right: 30px;
    min-height: 70px;
    min-width: 70px;
    margin-right: 30px;
}

.service-thumb-bg .post-thumb a {
    padding-bottom: 100% !important;
    display: block;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.media-body,
.elementor-7 .elementor-element.elementor-element-ee9cca3 .ts-feature-box .feature-icon {
    text-align: left;
}

.media-body {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.elementor-7 .elementor-element.elementor-element-ee9cca3 .ts-feature-box .media-body p {
    margin: 0px 0px 15px 0px;
}

.ts-feature-box.style2 .media-body p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.ts-feature-box .readmore {
    color: #FB7369;
}

.ts-feature-box .readmore {
    position: relative;
    font-weight: 700;
}


.ts-title.md a {
    color: var(--text-main, #263A5B);
    font-family: Open Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px;
    letter-spacing: -0.72px;
    text-transform: uppercase;
}

.dp-app-3 .heading-3 {
    color: #FFF !important;
    font-family: 'Open Sans';
    font-size: 50px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
    text-transform: uppercase;
}

.heading-3 p.ec-app {
    font-size: 30px;
    color: #fff;
    font-weight: 800;
    font-family: 'Open Sans';
    margin-top: 10px;
}

.dp-app-3 h4 {
    align-self: stretch;
    color: #FFF;
    font-family: 'Rubik';
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
}

.head-heading .settle-claim {
    width: 430px;
}

.card.card-flex {
    display: flex;
    /* flex-direction: row; */
    /* flex-wrap: nowrap; */
    border: none;
    align-items: center;
    margin-top: 40px;
    /* text-align: center; */
    justify-content: center;
}

.card-flex span {
    border-radius: 5px;
    background: #fb7369;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: "Mulish";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    display: flex;
    width: 50px;
    height: 50px;
    padding: 17px 17px 16px 18px;
    justify-content: center;
    align-items: center;
    position: absolute;

}

.card-text:last-child {
    width: 315px;
}

span.step-1 {
    left: 180px;
    top: -30px;
}

span.step-2 {
    left: 180px;
    top: -30px;
}

span.step-3 {
    left: 180px;
    top: -30px;
}

span.step-4 {
    left: 180px;
    top: -30px;
}

span.step-5 {
    left: 180px;
    top: -30px;
}

span.step-6 {
    left: 180px;
    top: -30px;
}

img.card-img-top {
    width: 175px;
    height: 165px;
    margin-left: -127px;
}

h5.card-title {
    color: #212529;
    font-family: 'Rubik';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    width: 260.591px;
    /* height: 134px; */
}

.card-text:last-child {
    color: #5A5A5A;
    font-family: 'Rubik';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.dividor-line {
    width: 100%;
    height: 1px;
    background: rgba(179, 179, 179, 0.5);
}

.tg-1.why-eseclaim-bg {
    background: #FFF;
}

h3.heading-3.text-dark {
    color: var(--text-main, #263A5B);
    text-align: center;
    font-family: 'Open Sans';
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
    text-transform: uppercase;
}

.insa-g h4,
.os-1 h4,
.our-process-1 h4 {

    width: 743px;
    color: #193656;
    text-align: center;
    font-family: 'Rubik';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.insa-g-in h5 {
    width: 293px;
    color: #000;
    font-family: 'Rubik';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.insa-g-in p {
    color: #5A5A5A;
    font-family: 'Rubik';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.faqs .faq-btns {
    display: flex;
    align-items: center;
    /* margin-bottom: 35px; */
}

.faqs .heading-3 {
    padding-bottom: 0;
}

.faq-1 .accordion-button {
    background: #F0F4FD;
    padding-bottom: 15px;
    padding-left: 30px;
    color: #2F3045;
    font-family: Rubik;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.accordion-body {
    padding-top: 0;
    color: #444;
    color: #2F3045;
    font-family: 'Rubik';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.contact-card .card-body {
    display: flex;
    height: 174px;
    padding: 49.5px 40px 69.5px 0px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    background: #FB7369;
}

.contact-card .card-body a,
.contact-card .card-body a:hover,
.contact-card .card-body span.tel-icon {
    text-decoration: none;
    color: #FFF;
    font-family: 'Open Sans';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    display: flex;
    padding-bottom: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    margin-left: 15px;
}

.contact-card .card-body a span {
    color: #FFF;
    font-family: 'Rubik';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-transform: capitalize;
}

.newsletter-card .card-body {
    display: flex;
    padding: 40px 40px 40px 60px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex: 1 0 0;
    background-color: #476BC7;
}

.newsletter-card .card-body h5.heading-5 {
    color: #FFF;
    font-family: 'Rubik';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

form#newsletter-form input#email_id {
    display: flex;
    width: 305px;
    min-height: 42px;
    padding: 17px 1px 16px 23px;
    align-items: flex-start;
    border-radius: 5px;
    border: 1px solid #114487;
    background: #114487;
    box-shadow: 0px 25px 40px 0px rgba(0, 52, 120, 0.20);
    color: #7F99BB;
}

form#newsletter-form {
    width: 305px;
    height: 50px;
    display: flex;
    align-items: center;
}

form#newsletter-form .right-arrow {
    margin-left: -30px;
}

.more-faqs {
    border-radius: 5px;

    color: #FFF;
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
}

.more-btn {
    width: 164px;
    height: 44px;
    border-radius: 5px;
    border: 2px solid #0F1F30;
    background: #0F1F30;
    text-align: center;
}

.slick-dots li button::before {
    width: 7px;
    height: 7px;
    border-radius: 3.5px;
    background: rgba(34, 47, 90, 0.20);
}

.slick-dots li.slick-active button::before {
    background: none;
    background-color: #fff;
    color: #fff;
    width: 15px;
    height: 15px;
    border-radius: 7.5px;
    border: 1px solid var(--primary-2, #476BC7);
}

.similar-issue-1 {
    margin-left: 0;
    margin-right: 0;
}

.more-btn a:hover {
    color: #fff;
    border: none;
    background: none;
    line-height: 40px;
}

.faqs .faq-btns a:hover,
.faqs .faq-btns .select-2 {
    color: #FFF;
    font-family: 'Rubik';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}

.offset-md-1 {
    margin: 0 0;
}

.sub-para p {
    color: #5A5A5A;
    text-align: center;
    font-family: 'Rubik';
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    width: 611px;
    justify-content: center;
    display: inline-flex;
}

.heading-3.text-light {
    padding-bottom: 65px;
}

.faq-ballon-icon {
    position: absolute;
    right: 0;
    top: 50%;
    margin-right: -520px;
}

.btn-secondry {
    color: #fff;
    border-radius: 5px;
    width: 100%;
    background: var(--primary-1, #FB7369);
}

form#newsletters-form {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
}

.footer-2 {
    padding: 9px 56.83px 11px 57.17px;
}

.footer-main {
    background: url('../../img/footer_bg.jpg');
    color: #193656;
    padding: 50px 0 0 0;
    font-size: 12px;
}

.footer-main .footer-1 h5 span,
.footer-2 h5 span {
    color: #2F3045;
    font-family: 'Open Sans';
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    text-transform: uppercase;
}

.footer-1 a,
.footer-1 a:hover {
    color: #B3B3B3;
    line-height: 30px;
    color: #193656;
    font-family: Rubik;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
}

input#inputAddress {
    display: flex;
    /* width: 253px; */
    /* min-height: 42px; */
    padding: 14px 30px 13px 30px;
    align-items: flex-start;
    border-radius: 4px;
    border: 1px solid #CED4DA;
    background: #FFF;
}

.news-form .subscribe-btn {
    display: flex;
    padding: 9px 56.83px 11px 57.17px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: var(--primary-1, #FB7369);
}

.footer-bottom {
    background: #2F3045;
    color: #fff;
    display: flex;
    /* width: 1366px; */
    padding: 34px 150px 15px 150px;
    align-items: flex-start;
}

.startup-logo {
    margin-top: 0;
}

.contain-bottom {
    display: flex;
    padding: 0px 20px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    /* background: #2F3045; */
    /* color: #E5E5E5; */
}

.footer-copyright {
    border-top: 1px solid #515151;
    align-self: stretch;
    background: #2F3045;
}

ul.social-footer li a,
.text-right a {
    color: #E5E5E5;
}

ul.social-footer {
    display: flex;
    align-items: center;
    gap: 35px;
}

.col-md-4 .footer-1 {
    margin-left: 0;
}

span.text-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 30px;
}

.subscribe-btn button,
.subscribe-btn .btn:hover {
    color: #FFF;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.top-bar-r .social-footer {
    margin-bottom: 0;
}

.top-bar-r ul.social-footer li a {
    color: #263A5B;
}

.pipe {
    width: 1px;
    height: 26px;
    border-left: 1px solid #DDD;
}

.top-bar-l ul li.pipe-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20.99px;
}

.ts-feature-box.style2 {
    padding: 20px 20px 20px 40px;
}

.row.ec-style4-service-list {
    margin: 2rem auto;
}

button.slick-arrow {
    opacity: 0.5 !important;
}

.section-dis {
    width: auto !important;
}

.tg-1-in .tagline-3 .slick-slide {
    height: 200px;
}

.tagline-we .slick-slide {
    height: 200px;
}

.tg-1 .our-process-1 {
    padding-bottom: 0;
}

.tagline-we .slick-track {
    margin-top: 30px;
}

.tg-1-in .tagline-3 .slick-list,
.tagline-we .slick-slide,
.insa-g .slick-slide {
    overflow: hidden;
    margin: 0;
    padding: 0;
    text-align: center;
}

.insa-g .slick-prev:before,
.insa-g .slick-next:before {
    color: rgba(255, 255, 255, 0.07);
}

/* 
.slick-next,
.slick-next:hover,
.slick-next:focus {
    background: url('../images/home/step/arrow.svg') center center no-repeat;
    text-indent: -9999px;
    opacity: 1 !important;
}

.slick-prev,
.slick-prev:hover,
.slick-prev:focus {
    background: url('../assets/image/banner/left-arrow.png') center center no-repeat;
    text-indent: -9999px;
    opacity: 1 !important;
} */

.tg-2 {
    display: flex;
    padding: 15px;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 35px 50px 0px rgba(0, 0, 0, 0.07);
    justify-content: center;
    text-align: left;
}

.carousel .carousel-item {
    color: #999;
    overflow: hidden;
    min-height: 120px;
    font-size: 13px;
}

.carousel .media img {
    width: 80px;
    height: 80px;
    display: block;
    border-radius: 50%;
}

.carousel .testimonial {
    padding: 0 15px 0 60px;
    position: relative;
}

.carousel .testimonial::before {
    content: "\201C";
    font-family: Arial, sans-serif;
    color: #e2e2e2;
    font-weight: bold;
    font-size: 68px;
    line-height: 54px;
    position: absolute;
    left: 15px;
    top: 0;
}

.tg-1-in .row.head {
    align-items: center;
}

.carousel .overview b {
    color: #476bc7;
    font-family: 'Rubik';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.carousel .overview p {
    color: #5B5B5B;
    font-family: 'MV Boli';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.carousel .carousel-indicators {
    bottom: -40px;
}

.testinomials-sec .head-heading .settle-claim {
    width: 484px;
}

.testinomials-sec .head-heading {
    align-items: stretch;
}

.similar-issue-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
}

.insa-g-in {
    border-radius: 5px;
}

.insa-g-in em img {
    border-radius: 5px;
}

h3.heading- {
    padding-bottom: 35px;
}

input#email_id::placeholder {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 200;
}

.text-quates .heading-4 {
    color: #193656;
    font-family: 'Rubik';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.customer-stify {
    border: 1px solid #222;
    border-radius: 5px;
}

.calendar-img-bg {
    width: 466.655px;
    height: 400px;
}

.register-with-us {
    display: none;
}

h3.heading- {
    padding-bottom: 35px;
    font-size: 36px;
}

p {
    color: #212529;
    font-family: 'Rubik';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.file-claim .testimonial p {
    width: 607px;
}

.faqs-form.faqs-bg.file-claim-faq .heading-3 {
    margin-bottom: 3rem;
}

.file-claim-faq .more-faqs {
    width: 175px;
}

.faq-1 .accordion-button::after {
    background-color: #fff0;
}

.footer-bottom p {
    color: #fff;
    width: 400px;
}

.fc-form {
    border-radius: 5px;
    border: 1px solid #000;
    background: #FFF;
    bottom: 75px !important;

}

.fc-counter-bg {
    background: none;
}

.heading-3 {
    font-size: 36px;
    line-height: normal;
}

.file-claim-process.fc-process {
    margin-top: -60px;
}

.fc-form {
    border-radius: 5px;
    border: 1px solid #193656;
    background: #FFF;
}

.contact-us .banner-form {
    display: inline-flex;
    padding: 40px 30px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 468px;
}

.maps-location {
    margin-top: 100px;
}

.maps-location iframe {
    width: 100%;
    height: 423px;
}

button#is-register-submit {
    margin-top: 10px;
    border-radius: 5px;
    width: 164px;
    margin-left: 0;
}

.col-md-10.contact-info-contain {
    display: inline-flex;
    padding: 20px 60px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.25);
}

div#fc-form\ got-question {
    display: inline-flex;
    padding: 40px 30px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    height: auto;
}



.more-faqs {
    width: 175px;
}

.faq-1 .accordion-button {
    font-size: 24px;
}

.extra-text {
    padding: 50px 0;
    margin-top: -130px;
    position: relative;
    z-index: 2;
    background: #fff;
}

.fc-form.fc-form-m-1.mystyle.reach-out-us {
    z-index: 4;
}

#fc-form\ reach-out-us h4 {
    color: #193656;
    font-family: Rubik;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}

.why-partners-r {
    margin-left: 5rem;
}

.insa-g-in h5 {
    width: 100%;
}

.insa-g-in {
    text-align: left;
}

.top-bar {
    background: #F0F4F9;
}
.top-bar-l a {
    color: #263A5B;
}
html,
body {
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box
}

.slider {
    width: 100%;
    margin: 0px auto
}

.slick-slide {
    margin: 0 15px
}

.slick-slide img {
    width: 100%
}

.slick-prev:before,
.slick-next:before {
    color: #000
}

.slick-slide {
    transition: all ease-in-out 1s;
    opacity: .5;
}

.slick-active {
    opacity: 1;
    transition: all ease-in-out 1s;
}

.slick-current {
    opacity: 1;
    transition: all ease-in-out 1s;
}

.hide {
    display: none;
}

.top-bar {
    background: #F0F4F9;
}

.top-bar-l a {
    color: #263A5B;
}
ol, ul, li {
    list-style: none;
}
a, a:hover {
    text-decoration: none;
}