/* src/AbhaCard.css */
.abha-card {
    /* max-width: 400px;
    margin: 50px auto;
    padding: 20px; */
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}



.abha-card .card-header {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    height: 100px;
    background-color: rgb(38, 68, 136);
    padding: 16px;

}
.header-center{
    text-align: center;
    color: rgb(255, 255, 255);
}

.abha-card .card-body {
    text-align: center;
    padding: 10px;
}

.abha-card .card-body p {
    margin: 0;
}

@media (min-width: 1024px) {
    .abha-number-header-title {
        font-size: 20px;
        line-height: 32px;
    }
}

@media (min-width: 0px) {
    .abha-number-header-title {
        font-size: 12px;
        line-height: 22px;
    }
}

.abha-number-header-title {
    margin: 0px 0px 8px;
    font-style: inherit;
    font-variant: inherit;
    font-stretch: inherit;
    font-family: inherit;
    font-optical-sizing: inherit;
    font-kerning: inherit;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    text-align: left;
    border-radius: 5px;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: 0.4px;
}
@media (min-width: 1024px) {
    .abha-number-header-subtitle {
        font-size: 20px;
    }
}


@media (min-width: 0px) {
    .abha-number-header-subtitle {
        font-size: 12px;
    }
}

.abha-number-header-subtitle {
    margin: 0px 0px 0.35em;
    letter-spacing: 0.00938em;
    font-style: inherit;
    font-variant: inherit;
    font-stretch: inherit;
    font-family: inherit;
    font-optical-sizing: inherit;
    font-kerning: inherit;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    text-align: left;
    border-radius: 5px;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}

@media (min-width: 768px) {
    .abha-content {
        flex-direction: row;
    }
}


@media (min-width: 0px) {
    .abha-content {
        flex-direction: column;
    }
}
.abha-content {
    display: flex;
    gap: 0px 32px;
}

/* .profile-img{
    display: flex;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: center;

} */
.top-info {
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
  }
.profile-img img{
    width: 106px;
    height: 128px;
    object-fit: contain;
}
.holder-name{
    margin: 0px;
    font-style: inherit;
    font-variant: inherit;
    font-stretch: inherit;
    font-family: inherit;
    font-optical-sizing: inherit;
    font-kerning: inherit;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    text-align: left;
    border-radius: 5px;
    width: 100%;
    font-size: 22px;
    line-height: 30px;
    font-weight: 600;
    letter-spacing: 0.48px;
    color: rgba(0, 0, 0, 0.87);
}
.card-title{
    margin: 0px;
    font-style: inherit;
    font-variant: inherit;
    font-stretch: inherit;
    font-family: inherit;
    font-optical-sizing: inherit;
    font-kerning: inherit;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    text-align: left;
    border-radius: 5px;
    width: 100%;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.36px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 600;
}
.title-body{
    margin-bottom: 16px;
}
.footer-body{
    display: flex;
    width: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-direction: row;
}

.info-list li {
    list-style: disc;
    text-align: left;
    padding: 10px 0;
  }